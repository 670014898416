export const vandaLinks = {
  marketingVideo: 'https://www.youtube.com/embed/rEQDCuoPtu0',
  termsAndConditions: '/terms',
  vandaCoverage: '/protection',
  travelWithVanda: 'https://www.youtube.com/embed/rEQDCuoPtu0',
  policies: '/policy',
  policiesText: 'Políticas generales',
  privacy: '/privacy',
  billing:
    'https://docs.google.com/forms/d/1eSxvmAsr8rk7Cz1uzBdBc8wUC0MoVTFvx4vb5Bcv8fs',
  phone: 'tel:55-1209-1270',
  email: 'mailto:contacto@vanda.app',
  emailText: 'contacto@vanda.app',
  facebook: 'https://www.facebook.com/viajaconVanda',
  instagram: 'https://www.instagram.com/vanda.app/',
};

export const rollBitsLinks = {
  faqs: 'https://rollbits.blog/preguntas-frecuentes/',
  privacy: 'https://rollbits.blog/aviso-de-privacidad/',
  termsAndConditions: 'https://rollbits.blog/terminos-y-condiciones/',
  billing: 'https://facturacion.roll-bits.com/#/inicio',
  email: 'mailto:servicioaclientes@roll-bits.com',
  emailText: 'servicioaclientes@roll-bits.com',
  facebook: 'https://www.facebook.com/RollandBits',
  instagram: 'https://www.instagram.com/rollandbits/',
  covid: 'https://rollbits.blog/acciones-tomadas-coronavirus/',
  coverage: 'https://rollbits.blog/coberturaadicional/',
  policies: 'https://rollbits.blog/preguntas-frecuentes/',
  policiesText: 'Preguntas Frecuentes',
  phone: 'tel:47-7175-4902',
  googleStore:
    'https://play.google.com/store/apps/details?id=com.web_view_rnb&hl=en&gl=US&pli=1',
  appleStore: 'https://apps.apple.com/mx/app/roll-bits/id1610430469',
};
