import mixpanel from 'mixpanel-browser';
import { screenSizes } from '@reservamos/elements';

export function getProduct() {
  const mediumSize = window.matchMedia(screenSizes.mediumAndUp);

  return mediumSize.matches ? 'web' : 'web-mobile';
}

export function init(token) {
  mixpanel.init(token);
}

export function identify(id) {
  mixpanel.identify(id);
}

export function alias(id) {
  mixpanel.alias(id);
}

export function getDistinctId() {
  return mixpanel.get_distinct_id();
}

export function track(name, props) {
  const eventProps = { ...props, Product: getProduct() };

  if (process.env.NODE_ENV === 'development') {
    console.log(`Mixpanel: ${name}`, eventProps);
  }

  mixpanel.track(name, eventProps);
}

export const people = {
  set(props) {
    mixpanel.people.set(props);
  },
};
