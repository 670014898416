import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { Spacing, Text, Link, Icon, Visibility } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import Logo from '../atoms/Logo';
import Navigation from './Navigation';
import LayoutContainer from '../atoms/LayoutContainer';
import { thirdLayer } from '../../src/tokens/zindexes';
import assetUrl from '../../utils/assetUrl';

const Header = ({ alt, logoImage, hasBorder, hasMenu, hasPhone }) => {
  const { t } = useTranslation('common');
  const { colors } = useTheme();
  const container = css`
    background-color: ${colors.white};
    border: 0;
    ${hasBorder && 'border-bottom: 3px solid'};
    ${hasBorder && 'border-image-slice: 1'};
    border-image-source: ${colors.headerBorder};
    padding: 15px 10px;
  `;
  const wrapper = css`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${thirdLayer};
  `;

  return (
    <div css={wrapper}>
      <div css={container}>
        <LayoutContainer>
          <Spacing alignItems="center" justifyContent="space-between">
            {/** TODO: Remove this condition when search home in R&B is available */}
            <a
              href={
                process.env.NEXT_PUBLIC_BRAND === 'roll-bits'
                  ? 'https://roll-bits.com'
                  : '/'
              }
              rel="noreferrer"
            >
              <Logo src={logoImage} alt={alt} />
            </a>

            <Spacing alignItems="center">
              {hasMenu && (
                <Visibility type="hideForMobileOnly">
                  <Navigation links={['routes', 'why', 'safety', 'faq']} />
                </Visibility>
              )}
              <Spacing size="XS" alignItems="flex-end" vertical>
                <Text size="XS" color="primaryStrong">
                  {t('', { context: 'help' })}
                </Text>
                {hasPhone && (
                  <Spacing size="XS" alignItems="center">
                    <Icon type={assetUrl('icons/phone.svg')} mobileSize="S" />
                    <Link
                      size="L"
                      type="primaryStrong"
                      textDecoration="none"
                      weight="bold"
                      text="55-1209-1270"
                      href="tel:55-1209-1270"
                    />
                  </Spacing>
                )}
              </Spacing>
            </Spacing>
          </Spacing>
        </LayoutContainer>
      </div>
      {hasMenu && (
        <Visibility type="showForMobileOnly">
          <Navigation links={['routes', 'why', 'safety', 'faq']} />
        </Visibility>
      )}
    </div>
  );
};

Header.propTypes = {
  /** Logo's image alt  */
  alt: PropTypes.string,
  /** Logo's image src  */
  logoImage: PropTypes.string,
  /** Sets if header has border */
  hasBorder: PropTypes.bool,
  hasMenu: PropTypes.bool,
  hasPhone: PropTypes.bool,
};

Header.defaultProps = {
  alt: null,
  logoImage: null,
  hasBorder: false,
  hasMenu: false,
  hasPhone: true,
};

export default Header;
