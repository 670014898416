import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Text, mediaQueries } from '@reservamos/elements';
import { useTranslation } from 'next-i18next';

const FooterAppsLinks = ({ googleStoreLink, appleStoreLink }) => {
  const { colors } = useTheme();
  const { t } = useTranslation('common');
  const wrapper = css`
    position: relative;
  `;
  const button = css`
    border-radius: 10px;
    border: 1.2px solid ${colors.gray600};
    min-width: 160px;
    padding: 18px 10px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    a:first-child {
      margin-bottom: 10px;
      ${mediaQueries.small} {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
    ${mediaQueries.small} {
      flex-direction: row;
    }
  `;
  const text = css`
    position: absolute;
    right: -14px;
    top: -7px;
    background: ${colors.white};
    padding: 5px 15px;
    ${mediaQueries.xsmall} {
      right: 0;
      padding: 5px 10px;
    }
  `;
  const img = css`
    width: 100px;
    ${mediaQueries.small} {
      width: 79px;
    }
  `;
  return (
    <div css={wrapper}>
      <div css={text}>
        <Text size="XS">{t('Download App')}</Text>
      </div>
      <div css={button}>
        <a href={googleStoreLink} target="_blank" rel="noopener noreferrer">
          <img css={img} src="shared/icons/googleplaygray.svg" alt="android" />
        </a>
        <a href={appleStoreLink} target="_blank" rel="noopener noreferrer">
          <img css={img} src="shared/icons/appstoregray.svg" alt="IOS" />
        </a>
      </div>
    </div>
  );
};
FooterAppsLinks.propTypes = {
  googleStoreLink: PropTypes.string.isRequired,
  appleStoreLink: PropTypes.string.isRequired,
};

FooterAppsLinks.defaultProps = {};

export default FooterAppsLinks;
