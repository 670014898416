import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import Header from '../ui/molecules/Header';
import Footer from '../ui/molecules/Footer';
import assetUrl from '../utils/assetUrl';
import SmallFooter from '../ui/molecules/SmallFooter';

const Layout = ({
  children,
  hasFixedElement,
  hasMenu,
  hasPhone,
  isStandAlone,
  background,
  hasSmallFooter,
}) => {
  const { colors } = useTheme();
  const backgroundStyles = css`
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  `;
  const content = css`
    padding: ${isStandAlone ? '0' : '65px 0 30px'};
    background-color: ${colors.gray100};
    ${background && `background-image: url(${background})`};
    ${background && backgroundStyles}

    ${mediaQueries.small} {
      ${hasMenu && 'padding-top: 120px'};
    }
  `;

  const wrapper = css`
    ${mediaQueries.small} {
      padding-bottom: ${hasFixedElement ? '60px' : ''};
    }
  `;

  return (
    <>
      <div css={wrapper}>
        <Spacing size="XS" vertical>
          {!isStandAlone && (
            <Header
              hasMenu={hasMenu}
              alt="Tribu Logo"
              logoImage={assetUrl('main/logo.svg')}
              hasBorder
              hasPhone={process.env.NEXT_PUBLIC_BRAND === 'vanda' && hasPhone}
            />
          )}
          <div css={content}>{children}</div>
          {!isStandAlone && (hasSmallFooter ? <SmallFooter /> : <Footer />)}
        </Spacing>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  /** Sets if has a fixed element */
  hasFixedElement: PropTypes.bool,
  hasMenu: PropTypes.bool,
  hasPhone: PropTypes.bool,
  /** Sets if Layout hasn't Header and Footer */
  isStandAlone: PropTypes.bool,
  background: PropTypes.bool,
  hasSmallFooter: PropTypes.bool,
};

Layout.defaultProps = {
  background: false,
  hasFixedElement: false,
  hasMenu: false,
  hasPhone: true,
  isStandAlone: false,
  hasSmallFooter: false,
};

export default Layout;
