import React from 'react';
import { mediaQueries, Text } from '@reservamos/elements';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Layout from '../components/Layout';
import ErrorInfo from '../ui/molecules/ErrorInfo';
import assetUrl from '../utils/assetUrl';

const ErrorView = ({
  title,
  buttonText,
  text,
  isReload,
  linkText,
  linkHref,
}) => {
  const layout = css`
    padding: 15vh 0 15vh 0;

    ${mediaQueries.small} {
      padding: 10vh 0 10vh 0;
    }
  `;

  return (
    <Layout background={assetUrl('error/background-error.png')}>
      <div css={layout}>
        <ErrorInfo
          imageSrc={assetUrl('error/error.svg')}
          imageAlt="Error"
          title={title}
          buttonText={buttonText}
          isReload={isReload}
          linkText={linkText}
          linkHref={linkHref}
        >
          <Text size="M" color="grayMedium" textAlign="center">
            {text}
          </Text>
        </ErrorInfo>
      </div>
    </Layout>
  );
};

ErrorView.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  isReload: PropTypes.bool,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
};

ErrorView.defaultProps = {
  title: 'Ups! Ha ocurrido un error',
  buttonText: 'Regresar',
  text: 'Estamos trabajando para solucionarlo.',
  isReload: false,
  linkText: '',
  linkHref: '',
};

export default ErrorView;
