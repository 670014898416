import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Text, mediaQueries, Spacing } from '@reservamos/elements';
import assetUrl from '../../utils/assetUrl';

const FooterPaymentMethod = ({ label }) => {
  const { colors } = useTheme();
  const imgInsurance = css`
    width: 20px;
  `;
  const wrapper = css`
    position: relative;
  `;
  const iconsWrapper = css`
    border-radius: 10px;
    border: 1.5px solid ${colors.primary};
    min-width: 400px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    ${mediaQueries.small} {
      min-width: 100%;
      justify-content: center;
      padding: 15px;
    }
  `;
  const text = css`
    position: absolute;
    left: -24px;
    background: ${colors.white};
    padding: 5px 10px;
    ${mediaQueries.small} {
      top: -17px;
      left: 14px;
    }
  `;
  const imgPayment = css`
    width: 55px;
    padding: 5px;
  `;

  return (
    <div css={wrapper}>
      <div css={iconsWrapper}>
        <div css={text}>
          <Spacing alignItems="center" size="S">
            <img
              css={imgInsurance}
              src={assetUrl('icons/insurance.svg')}
              alt="insurance"
            />
            <Text size="XS" color="primary" weight="bold">
              {label}
            </Text>
          </Spacing>
        </div>
        <img css={imgPayment} src={assetUrl('icons/visa.svg')} alt="Visa" />
        <img
          css={imgPayment}
          src={assetUrl('icons/mastercard.svg')}
          alt="Mastercard"
        />
        <img css={imgPayment} src={assetUrl('icons/american.svg')} alt="Amex" />
        <img
          css={imgPayment}
          src={assetUrl('icons/paypal_original.svg')}
          alt="Paypal"
        />
        <img css={imgPayment} src={assetUrl('icons/oxxo.svg')} alt="oxxo" />
      </div>
    </div>
  );
};
FooterPaymentMethod.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FooterPaymentMethod;
